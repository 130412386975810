import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { ShapeInput } from "../../shape/shape.model";

interface ImageResizerOptions {
  coordinates: ShapeInput[]; // Coordinates to overlay
  lengthArr: {l1: number, l2: number, l3: number, l4: number, l5: number, l6: number}
}

@Directive({
  selector: "[appImageDrawer]",
})
export class ImageDrawerDirective {
  @Input('appImageDrawer') drawOptions: ImageResizerOptions;

  constructor(private elementRef: ElementRef) {}
  @HostListener("load", ["$event.target"])
  onImageLoad(image: HTMLImageElement) {
    const targetWidth = 90;
    const aspectRatio = image.height / image.width;
    const targetHeight = 90;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = targetWidth;
    canvas.height = targetHeight;

    if(context){
      context.clearRect(0, 0, targetWidth, targetHeight);
      context.drawImage(image, 0, 0, targetWidth, targetHeight);

      context.fillStyle = 'red';
      
      this.drawOptions.coordinates.forEach((coord, index) => {        
        const x = ((index % 5) * 75) + coord.position.x + 10;
        const y = (index > 4 ? 35 : 0) + coord.position.y + 30;
        context.fillStyle = 'red'; // Set character color
        context.font = 'bold 9px Arial'; // Set font style
        context.fillText(this.getLength(coord.name), x * 0.225, y * 0.225); // Draw character
      });
    }
    // Convert the canvas to an image
    const dataURL = canvas.toDataURL("image/png");
    const newImage = new Image();
    newImage.src = dataURL;
    newImage.width = targetWidth;
    newImage.height = targetHeight;
    
    if(image.parentNode)
    image.parentNode.replaceChild(newImage, image);
  }

  getLength(name: string): string {
    if(!(this.drawOptions && this.drawOptions.lengthArr)) return '';
    switch(name) {
      case 'A' : return this.drawOptions.lengthArr.l1 ? (Math.floor(this.drawOptions.lengthArr.l1 * 1000)).toString() : '-';
      case 'B' : return this.drawOptions.lengthArr.l2 ? (Math.floor(this.drawOptions.lengthArr.l2 * 1000)).toString() : '-';
      case 'C' : return this.drawOptions.lengthArr.l3 ? (Math.floor(this.drawOptions.lengthArr.l3 * 1000)).toString() : '-';
      case 'D' : return this.drawOptions.lengthArr.l4 ? (Math.floor(this.drawOptions.lengthArr.l4 * 1000)).toString() : '-';
      case 'E' : return this.drawOptions.lengthArr.l5 ? (Math.floor(this.drawOptions.lengthArr.l5 * 1000)).toString() : '-';
      case 'F' : return this.drawOptions.lengthArr.l6 ? (Math.floor(this.drawOptions.lengthArr.l6 * 1000)).toString() : '-';
      default: return '';
    }
  }
}
